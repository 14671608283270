<nav id="navPanel" class="bg-light border-bottom box-shadow fixed-top navbar-expand-lg">
	<app-header></app-header>
</nav>
<div class="main-panel pt-0 pb-4">
    <div class="container-fluid" #main [style.padding-top.px]="paddingtop">
        <div class="col-sm-12 px-0 align-self-end">
          <div class="row align-items-center">
            <div class="col-sm-9">
              <h3 class="light-header bold">
                Account Allocation Request Form
              </h3>
            </div>
            <div class="col-sm-3">
              <button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
                <i class="fas fa-sign-out"></i> Exit
              </button>
              <button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
                {{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
              </button>
            </div>
          </div>
          <hr class="mt-0 mb-3" />
        </div>
        
        <div class="pt-3 row">
          <div class="col-sm-3">
          </div>
          <div class="col-sm-6">
            <div class="container border rounded bold">
              <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
                  <h4 class="light-header bold pt-2 pb-2">
                      Account Allocation Request History
                  </h4>
                <div class="col-sm-12 px-0" >
                  <ag-grid-angular style="width: 100%; height:400px; " class="ag-theme-balham bold"
                    [columnDefs]="columnDefs0" (gridReady)="onGridReady0($event)" [rowData]="rowData0"
                    [headerHeight]="40" [gridOptions]="gridOptions0" (cellValueChanged)="onCellValueChanged($event)"
                    (gridSizeChanged)="gridSizeChanged($event)">
                  </ag-grid-angular>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-3 row">
          <div class="col-sm-3">
          </div>
          <div class="col-sm-6">
          <div class="container border rounded bold">
            <h4 class="light-header bold p-4">
              Account Allocation Request Form
            </h4>
          <form [formGroup]="iSForm" class="p-4">
            <!-- <div class="form-group row">
              <label for="advisorname" class="col-sm-12 col-form-label">Advisor Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="advisorname"  autocomplete="off"
                       [(ngModel)]="advisorname"  formControlName="advisorname"/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div> -->


            <div class="form-group row">
              <label for="advisorname" class="col-sm-12 col-form-label">Advisor Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <select name="advisorname" class="form-control bold col-sm-6" style="font-size: 14px;" 
                 [(ngModel)]="advisorname" formControlName="advisorname">
                    <option *ngFor="let advisor of advisorsArray" 
                    [value]="advisor.id">
                      {{advisor.text}}
                    </option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="accountname" class="col-sm-12 col-form-label">Company Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="companyname"  autocomplete="off"
                       [(ngModel)]="companyname"  formControlName="companyname" disabled/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="login" class="col-sm-12 col-form-label">Login</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="login"  autocomplete="off"
                       [(ngModel)]="login"  formControlName="login" disabled/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="lastname" class="col-sm-12 col-form-label">Client Last Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="lastname"  autocomplete="off"
                       [(ngModel)]="lastname"  formControlName="lastname"
                       (change)="GenerateAccountName()"/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="firstname" class="col-sm-12 col-form-label">Client First Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="firstname"  autocomplete="off"
                       [(ngModel)]="firstname"  formControlName="firstname"
                       (change)="GenerateAccountName()"/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row" [hidden]="!showClient2Details">
              <label for="lastnameclient2" class="col-sm-12 col-form-label">Client 2 Last Name</label>
            </div>
            <div class="form-group row" [hidden]="!showClient2Details">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="lastnameclient2"  autocomplete="off"
                       [(ngModel)]="lastnameclient2"  formControlName="lastnameclient2"
                       (change)="GenerateAccountName()"/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row" [hidden]="!showClient2Details">
              <label for="firstnameclient2" class="col-sm-12 col-form-label">Client 2 First Name</label>
            </div>
            <div class="form-group row" [hidden]="!showClient2Details">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="firstnameclient2"  autocomplete="off"
                       [(ngModel)]="firstnameclient2"  formControlName="firstnameclient2"
                       (change)="GenerateAccountName()"/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row float-right mb-3" [hidden]="showClient2Details">
              <button type="button" class="btn btn-primary btn-sm" (click)="addClient2()">
                  <i class="fas fa-plus"></i> Add Client 2
              </button>
            </div>
            
            <div class="form-group row float-right mb-3" [hidden]="!showClient2Details">
              <button type="button" class="btn btn-primary btn-sm" (click)="removeClient2()">
                  <i class="fas fa-minus"></i> Remove Client 2
              </button>
            </div>

            <div class="form-group row">
              <label for="accountclassification" class="col-sm-12 col-form-label pl-0">Account Classification</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <select name="accountclassification" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="accountclassification" formControlName="accountclassification"
                (change)="resetAccountClassification($event)">
                    <option>---</option>  
                    <option>Qualified</option>
                    <option>Non-Qualified</option>
                    <option>Entity</option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row" [hidden]="showAccountTypeQualified"> 
              <label for="accounttype" class="col-sm-12 col-form-label">Account Type</label>
            </div>
            <div class="form-group row" id="accounttypequalified" [hidden]="showAccountTypeQualified">
              <div class="col-sm-12">
                <select name="accounttypequalified" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="accounttypequalified" formControlName="accounttypequalified"
                (change)="resetAccountType($event)">
                    <option>---</option>  
                    <option>IRA</option>
                    <option>ROTH</option>
                    <option>BDA</option>
                    <option>401k</option>
                    <option>Other Qualified</option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row" [hidden]="showAccountTypeNonQualified"> 
              <label for="accounttype" class="col-sm-12 col-form-label">Account Type</label>
            </div>
            <div class="form-group row"id="accounttypenonqualified" [hidden]="showAccountTypeNonQualified">
              <div class="col-sm-12">
                <select name="accounttypenonqualified" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="accounttypenonqualified" formControlName="accounttypenonqualified"
                (change)="resetAccountType($event)">
                    <option>---</option>  
                    <option>Individual</option>
                    <option>Joint</option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row" [hidden]="showAccountTypeEntity"> 
              <label for="accounttype" class="col-sm-12 col-form-label">Account Type</label>
            </div>
            <div class="form-group row"id="accounttypeentity" [hidden]="showAccountTypeEntity">
              <div class="col-sm-12">
                <select name="accounttypeentity" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="accounttypeentity" formControlName="accounttypeentity"
                (change)="resetAccountType($event)">
                    <option>---</option>  
                    <option>Trust</option>
                    <option>Corp</option>
                    <option>FLP</option>
                    <option>Other Entity</option>
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="accountname" class="col-sm-12 col-form-label">Account Name</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                  <input type="text" class="form-control bold" id="accountname"  autocomplete="off"
                       [(ngModel)]="accountname"  formControlName="accountname" readonly/> 
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

            <div class="form-group row">
              <label for="accountnumber" class="col-sm-12 col-form-label">Account Number</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                    <input type="text" class="form-control bold" id="accountnumber"  autocomplete="off"
                       [(ngModel)]="accountnumber"  formControlName="accountnumber" maxlength="20"/> 
                    <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>

           

            <div class="form-group row">
              <label for="custodian" class="col-sm-12 col-form-label">Custodian</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                <select name="custodian" class="form-control bold col-sm-6" style="font-size: 14px;" 
                placeholder="Select your answer" [(ngModel)]="custodian" formControlName="custodian"
                (change)="resetCustodian($event)">
                    <option>---</option>   
                    <option>TD Ameritrade</option>
                    <option>E-Trade</option>
                    <option>Interactive Brokers</option> 
                    <option>Schwab</option> 
                </select>
                <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>


            <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label">Instruction Type</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12">
                  <select name="instructiontype" class="form-control bold col-sm-6" style="font-size: 14px;" 
                  placeholder="Select your answer" [(ngModel)]="instructiontype" formControlName="instructiontype"
                  (change)="resetInstructionType($event)">
                    <option>---</option>   
                    <option>New Investment</option>
                    <option>Addt'l Funds</option>
                    <option>Re-Allocation</option>
                  </select>
                  <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
              </div>
            </div>


            <!-- <div class="form-group row">
              <label for="highyieldcorporatebond" class="col-sm-12 col-form-label">High Yield Corporate Bond ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="highyieldcorporatebond"  autocomplete="off"
                       [(ngModel)]="highyieldcorporatebond" type='number' formControlName="highyieldcorporatebond"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>
            

            <div class="form-group row">
              <label for="strategicenhancedbond" class="col-sm-12 col-form-label">Strategic Enhanced Bond ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="strategicenhancedbond"  autocomplete="off"
                       [(ngModel)]="strategicenhancedbond"  type='number' formControlName="strategicenhancedbond"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="logicinvestsleepwellbond" class="col-sm-12 col-form-label">Logic Invest Sleep Well Bond ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="logicinvestsleepwellbond"  autocomplete="off"
                       [(ngModel)]="logicinvestsleepwellbond"  type='number' formControlName="logicinvestsleepwellbond"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="strategichedgedincome" class="col-sm-12 col-form-label">Strategic Hedged Income ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="strategichedgedincome"  autocomplete="off"
                       [(ngModel)]="strategichedgedincome"  type='number' formControlName="strategichedgedincome"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="economiccycle" class="col-sm-12 col-form-label">Economic Cycle ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="economiccycle"  autocomplete="off"
                       [(ngModel)]="economiccycle"  type='number' formControlName="economiccycle"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>



            <div class="form-group row">
              <label for="foundation" class="col-sm-12 col-form-label">Foundation($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="foundation"  autocomplete="off"
                       [(ngModel)]="foundation" type='number' formControlName="foundation"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="logicinvestglobalsector" class="col-sm-12 col-form-label">Logic Invest Global Sector ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="logicinvestglobalsector"  autocomplete="off"
                       [(ngModel)]="logicinvestglobalsector" type='number' formControlName="logicinvestglobalsector"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>



            <div class="form-group row">
              <label for="logicinvestcountryrotation" class="col-sm-12 col-form-label">Logic Invest Country Rotation ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="logicinvestcountryrotation"  autocomplete="off"
                       [(ngModel)]="logicinvestcountryrotation" type='number' formControlName="logicinvestcountryrotation"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="primedividendinternational" class="col-sm-12 col-form-label">Prime Dividend International ($5,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="primedividendinternational"  autocomplete="off"
                       [(ngModel)]="primedividendinternational" type='number' formControlName="primedividendinternational"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>



            <div class="form-group row">
              <label for="strategicmidcap" class="col-sm-12 col-form-label">Strategic Mid Cap ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="strategicmidcap"  autocomplete="off"
                       [(ngModel)]="strategicmidcap" type='number' formControlName="strategicmidcap"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="usprimedividend" class="col-sm-12 col-form-label">U.S. Prime Dividend ($5,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="usprimedividend"  autocomplete="off"
                       [(ngModel)]="usprimedividend" type='number' formControlName="usprimedividend"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="NASDAQleaders" class="col-sm-12 col-form-label">NASDAQ Leaders ($5,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="NASDAQleaders"  autocomplete="off"
                       [(ngModel)]="NASDAQleaders" type='number' formControlName="NASDAQleaders"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="sectorrotation" class="col-sm-12 col-form-label">Sector Rotation ($5,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="sectorrotation"  autocomplete="off"
                       [(ngModel)]="sectorrotation" type='number' formControlName="sectorrotation"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div>


            <div class="form-group row">
              <label for="valuediscount" class="col-sm-12 col-form-label">Value Discount ($1,000 min. investment)</label>
              <br/>
              <label for="percentage" class="col-sm-2 col-form-label">Enter a %</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="valuediscount"  autocomplete="off"
                       [(ngModel)]="valuediscount" type='number' formControlName="valuediscount"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"/> 
              </div>
            </div> -->

            <div class="form-group row">
              <label for="accountsize" class="col-sm-12 col-form-label">Account Size</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="accountsize"  autocomplete="off"
                       [(ngModel)]="accountsize" type='text' formControlName="accountsize" min="1"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()"
                       (focusout)="ValidateMinInvestmentOnFocusOut()" maxlength="20"/> 
              </div>
            </div>

            <div class="form-group row">
              <label for="pendingannuities" class="col-sm-12 col-form-label">Pending Annuities</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-6"> 
                 <input type="text" class="form-control bold" id="pendingannuities"  autocomplete="off"
                       [(ngModel)]="pendingannuities" type='text' formControlName="pendingannuities" min="0"
                       (keyup)="FilterInput($event)" (change) = "clearAlert()" 
                       (focusout)="ValidateMinInvestmentOnFocusOut()" /> 
              </div>
            </div>

            <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label pb-0">Lower Risk Strategies</label>
            </div>
            <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0" >
                <ag-grid-angular style="width: 100%; height: 190px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs1" (gridReady)="onGridReady1($event)" [rowData]="rowData1"
                  [headerHeight]="40" [gridOptions]="gridOptions1" (cellValueChanged)="onCellValueChanged($event)"
                  (gridSizeChanged)="gridSizeChanged($event)">
                </ag-grid-angular>
              </div>
            </div>

            <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label pb-0">Moderate Risk Strategies</label>
            </div>
            <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0" >
                <ag-grid-angular style="width: 100%; height: 350px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs2" (gridReady)="onGridReady2($event)" [rowData]="rowData2"
                  [headerHeight]="40" [gridOptions]="gridOptions2" (cellValueChanged)="onCellValueChanged($event)"
                  (gridSizeChanged)="gridSizeChanged($event)">
                </ag-grid-angular>
              </div>
            </div>

            <div class="form-group row">
              <label for="instructiontype" class="col-sm-12 col-form-label pb-0">Growth Strategies</label>
            </div>
            <div id="grid-wrapper" class="col-sm-12 px-0" style="width: 100%; overflow: hidden;">
              <div class="col-sm-12 px-0 pb-4" >
                <ag-grid-angular style="width: 100%; height: 220px; " class="ag-theme-balham bold"
                  [columnDefs]="columnDefs3" (gridReady)="onGridReady3($event)" [rowData]="rowData3" [pinnedBottomRowData]="pinnedBottomRowData"
                  [headerHeight]="40" [gridOptions]="gridOptions3" (cellValueChanged)="onCellValueChanged($event)"
                  (gridSizeChanged)="gridSizeChanged($event)">
                </ag-grid-angular>
              </div>
            </div> 

            <div class="form-group row">
              <label for="heldpositions" class="col-sm-12 col-form-label">Held Positions</label>
            </div>
            <div class="form-group row">
              <div class="col-sm-12"> 
                 <input type="text" class="form-control bold" id="heldpositions"  autocomplete="off"
                       [(ngModel)]="heldpositions"  formControlName="heldpositions"/> 
              </div>
            </div>

            <div class="form-group row">
                <label for="uploadforms" class="col-sm-12 col-form-label">Upload Forms</label>
                <br/>
                <label for="percentage" class="col-sm-12">Upload the complete account allocation request form, and the signed ADV acknowledgement if it is a new account</label>
              </div>
            <div class="form-group row">
                <div class="col-sm-12"> 
                  <input type="file" class="form-control bold col-sm-6" id="file" autocomplete="off" [(ngModel)]="file" formControlName="file"
                    (change)="handleFileInput($event)" value="file" accept=".pdf,.doc,.docx,.xlsx,.pptx,.jpg,.jpeg,.png,.txt" multiple/> 
                    <small for="required" class="col-sm-2 col-form-label float-right" style="color: #b0b0b5; font-style: italic">*Required</small>
                </div>          
            </div>

            <div class="form-group pt-3">
              <div *ngFor="let file of fileInfos" class="row">
                  <label class="col-sm-10 col-form-label">{{ file.name }}</label>                 
                  <button type="button" class="close" (click)= "removeFile(file)">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
            </div>


            <div class="form-group row">
                <label for="notes" class="col-sm-12 col-form-label">Notes</label>
            </div>
            <div class="form-group row">
                <div class="col-sm-12"> 
                    <textarea class="form-control bold"  rows="6" [(ngModel)]="notes" formControlName="notes"
                    name="notes" id="notes"></textarea> 
                </div>
            </div>
            <div class="form-group row float-right p-3">
              <button type="button" class="btn btn-primary btn-sm" (click)="saveMondayAllocationDetails()" [disabled]="disablesubmitbtn">
                  <i class="fas fa-sign-out"></i> Submit
              </button>
            </div>
          </form>
        </div>
        </div>
        </div>
      </div>
    </div>


    <ng-template #show_allocation_request_details>
      <div class="modal-md">
        <div class="modal-header">
            <h5 class="modal-title">Allocation Request Details</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="cancelModal()">
              <span aria-hidden="true">×</span>
            </button>
        </div>
        <div class="modal-body">
        <div class="container-fluid bold">
              <div>
                <div class="container border rounded p-3 bold">
                    <form [formGroup]="iSFormAllocationRequestDetails">
                      <div class="form-group row" [hidden]="!showSubmissionDate">
                        <label for="submissionDateInPopUp" class="col-sm-5 col-form-label">Submission Date</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="submissionDateInPopUp" autocomplete="off"
                           [(ngModel)]="submissionDateInPopUp" formControlName="submissionDateInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showGroup">
                        <label for="groupInPopUp" class="col-sm-5 col-form-label">Group Name</label>
                        <div class="col-sm-7"> 
                           <input type="text" class="form-control bold" id="groupInPopUp" autocomplete="off"
                           [(ngModel)]="groupInPopUp" formControlName="groupInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAdvisorName">
                        <label for="advisorNameInPopUp" class="col-sm-5 col-form-label">Advisor Name</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="advisorNameInPopUp" autocomplete="off"
                           [(ngModel)]="advisorNameInPopUp" formControlName="advisorNameInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showCompanyName">
                        <label for="companyNameInPopUp" class="col-sm-5 col-form-label">Company Name</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="companyNameInPopUp" autocomplete="off"
                          [(ngModel)]="companyNameInPopUp" formControlName="companyNameInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showLogin">
                        <label for="loginInPopUp" class="col-sm-5 col-form-label">Client's Name</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="loginInPopUp" autocomplete="off"
                          [(ngModel)]="loginInPopUp" formControlName="loginInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAccountClassification">
                        <label for="accountClassificationInPopUp" class="col-sm-5 col-form-label">Account Classification</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="accountClassificationInPopUp" autocomplete="off"
                          [(ngModel)]="accountClassificationInPopUp" formControlName="accountClassificationInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAccountType">
                        <label for="accountTypeInPopUp" class="col-sm-5 col-form-label">Account Type</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="accountTypeInPopUp" autocomplete="off"
                          [(ngModel)]="accountTypeInPopUp" formControlName="accountTypeInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAccountName">
                        <label for="accountNameInPopUp" class="col-sm-5 col-form-label">Account Name</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="accountNameInPopUp" autocomplete="off"
                          [(ngModel)]="accountNameInPopUp" formControlName="accountNameInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAccountNumber">
                        <label for="accountNumberInPopUp" class="col-sm-5 col-form-label">Account Number</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="accountNumberInPopUp" autocomplete="off"
                          [(ngModel)]="accountNumberInPopUp" formControlName="accountNumberInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showCustodian">
                        <label for="custodianInPopUp" class="col-sm-5 col-form-label">Custodian</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="custodianInPopUp" autocomplete="off"
                           [(ngModel)]="custodianInPopUp" formControlName="custodianInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showInstructionType">
                        <label for="instructionTypeInPopUp" class="col-sm-5 col-form-label">Instruction Type</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="instructionTypeInPopUp" autocomplete="off"
                           [(ngModel)]="instructionTypeInPopUp" formControlName="instructionTypeInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAccountSize">
                        <label for="accountSizeInPopUp" class="col-sm-5 col-form-label">Account Size</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="accountSizeInPopUp" autocomplete="off"
                           [(ngModel)]="accountSizeInPopUp" formControlName="accountSizeInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showPendingAnnuities">
                        <label for="pendingAnnuitiesInPopUp" class="col-sm-5 col-form-label">Pending Annuities</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="pendingAnnuitiesInPopUp" autocomplete="off"
                           [(ngModel)]="pendingAnnuitiesInPopUp" formControlName="pendingAnnuitiesInPopUp" disabled/> 
                        </div>
                      </div>
                      <div class="container border rounded p-1 mb-3 bold" [hidden]="!showLowerRiskStrategiesLabel">
                      <div class="form-group row">
                        <label class="col-sm-12 col-form-label" [hidden]="!showLowerRiskStrategiesLabel">Lower Risk Strategies</label>
                      </div>
                      <div class="form-group row" [hidden]="!showStrategicHedgedIncome">
                        <label for="strategicHedgedIncomeInPopUp" class="col-sm-5 col-form-label">Strategic Hedged Income</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="group" autocomplete="off"
                           [(ngModel)]="strategicHedgedIncomeInPopUp" formControlName="strategicHedgedIncomeInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showHighYieldCorporateBond">
                        <label for="highYieldCorporateBondInPopUp" class="col-sm-5 col-form-label">High Yield Corporate Bond</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="highYieldCorporateBondInPopUp" autocomplete="off"
                           [(ngModel)]="highYieldCorporateBondInPopUp" formControlName="highYieldCorporateBondInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showStrategicEnhancedBond">
                        <label for="strategicEnhancedBondInPopUp" class="col-sm-5 col-form-label">Strategic Enhanced Bond</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="strategicEnhancedBondInPopUp" autocomplete="off"
                           [(ngModel)]="strategicEnhancedBondInPopUp" formControlName="strategicEnhancedBondInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showSleepWellBond">
                        <label for="sleepWellBondInPopUp" class="col-sm-5 col-form-label">Sleep Well Bond</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="sleepWellBondInPopUp" autocomplete="off"
                           [(ngModel)]="sleepWellBondInPopUp" formControlName="sleepWellBondInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showCashIsKingStrategy">
                        <label for="cashIsKingStrategyInPopUp" class="col-sm-5 col-form-label">Cash is King Strategy</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="cashIsKingStrategyInPopUp" autocomplete="off"
                           [(ngModel)]="cashIsKingStrategyInPopUp" formControlName="cashIsKingStrategyInPopUp" disabled/>   
                        </div>
                      </div>
                      </div>
                      <div class="container border rounded p-1 mb-3 bold" [hidden]="!showModerateRiskStrategiesLabel">
                      <div class="form-group row">
                        <label class="col-sm-12 col-form-label" [hidden]="!showModerateRiskStrategiesLabel">Moderate Risk Strategies</label>
                      </div>
                      <div class="form-group row" [hidden]="!showFoundation">
                        <label for="foundationInPopUp" class="col-sm-5 col-form-label">Foundation</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="foundationInPopUp" autocomplete="off"
                           [(ngModel)]="foundationInPopUp" formControlName="foundationInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showUSPrimeDividend">
                        <label for="uSPrimeDividendInPopUp" class="col-sm-5 col-form-label">US Prime Dividend</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="uSPrimeDividendInPopUp" autocomplete="off"
                           [(ngModel)]="uSPrimeDividendInPopUp" formControlName="uSPrimeDividendInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showUSPrimeDividendJr">
                        <label for="uSPrimeDividendJrInPopUp" class="col-sm-5 col-form-label">US Prime Dividend Jr</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="uSPrimeDividendJrInPopUp" autocomplete="off"
                           [(ngModel)]="uSPrimeDividendJrInPopUp" formControlName="uSPrimeDividendJrInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showGlobalSector">
                        <label for="globalSectorInPopUp" class="col-sm-5 col-form-label">Global Sector</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="globalSectorInPopUp" autocomplete="off"
                           [(ngModel)]="globalSectorInPopUp" formControlName="globalSectorInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showPrimeDividendInternational">
                        <label for="primeDividendInternationalInPopUp" class="col-sm-5 col-form-label">Prime Dividend International</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="primeDividendInternationalInPopUp" autocomplete="off"
                           [(ngModel)]="primeDividendInternationalInPopUp" formControlName="primeDividendInternationalInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showEconomicCycle">
                        <label for="economicCycleInPopUp" class="col-sm-5 col-form-label">Economic Cycle</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="economicCycleInPopUp" autocomplete="off"
                           [(ngModel)]="economicCycleInPopUp" formControlName="economicCycleInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showStrategicMidCap">
                        <label for="strategicMidCapInPopUp" class="col-sm-5 col-form-label">Strategic Mid Cap</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="group" autocomplete="off"
                           [(ngModel)]="strategicMidCapInPopUp" formControlName="strategicMidCapInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showCountryRotation">
                        <label for="countryRotationInPopUp" class="col-sm-5 col-form-label">Country Rotation</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="countryRotationInPopUp" autocomplete="off"
                           [(ngModel)]="countryRotationInPopUp" formControlName="countryRotationInPopUp" disabled/>   
                        </div>
                      </div>
                      <!-- <div class="form-group row" [hidden]="!showShareholderTotalReturn">
                        <label for="shareholderTotalReturnInPopUp" class="col-sm-5 col-form-label">Shareholder Total Return</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="shareholderTotalReturnInPopUp" autocomplete="off"
                           [(ngModel)]="shareholderTotalReturnInPopUp" formControlName="shareholderTotalReturnInPopUp" disabled/>   
                        </div>
                      </div> -->
                      <div class="form-group row" [hidden]="!showSectorRotation">
                        <label for="sectorRotationInPopUp" class="col-sm-5 col-form-label">Sector Growth Strategy</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="sectorRotationInPopUp" autocomplete="off"
                           [(ngModel)]="sectorRotationInPopUp" formControlName="sectorRotationInPopUp" disabled/>   
                        </div>
                      </div>
                      </div>
                      <div class="container border rounded p-1 mt-3 bold" [hidden]="!showGrowthStrategiesLabel">
                      <div class="form-group row">
                        <label class="col-sm-12 col-form-label" [hidden]="!showGrowthStrategiesLabel">Growth Strategies</label>
                      </div>
                      <div class="form-group row" [hidden]="!showNASDAQLeaders">
                        <label for="nASDAQLeadersInPopUp" class="col-sm-5 col-form-label">NASDAQ Leaders</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="nASDAQLeadersInPopUp" autocomplete="off"
                           [(ngModel)]="nASDAQLeadersInPopUp" formControlName="nASDAQLeadersInPopUp" disabled/>  
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showValueDiscount">
                        <label for="valueDiscountInPopUp" class="col-sm-5 col-form-label">Value Discount</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="valueDiscountInPopUp" autocomplete="off"
                           [(ngModel)]="valueDiscountInPopUp" formControlName="valueDiscountInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showValueExposure">
                        <label for="valueExposureInPopUp" class="col-sm-5 col-form-label">Broad Value</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="valueExposureInPopUp" autocomplete="off"
                           [(ngModel)]="valueExposureInPopUp" formControlName="valueExposureInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showAdaptiveRotation">
                        <label for="adaptiveRotationInPopUp" class="col-sm-5 col-form-label">Adaptive Rotation Strategy</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="adaptiveRotationInPopUp" autocomplete="off"
                           [(ngModel)]="adaptiveRotationInPopUp" formControlName="adaptiveRotationInPopUp" disabled/>   
                        </div>
                      </div>
                      </div>
                      <div class="form-group row" [hidden]="!showHeldPositions">
                        <label for="heldPositionsInPopUp" class="col-sm-5 col-form-label">Held Positions</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="heldPositionsInPopUp" autocomplete="off"
                           [(ngModel)]="heldPositionsInPopUp" formControlName="heldPositionsInPopUp" disabled/>   
                        </div>
                      </div>
                      <div class="form-group row" [hidden]="!showNotes">
                        <label for="notesInPopUp" class="col-sm-5 col-form-label">Notes</label>
                        <div class="col-sm-7">
                          <input type="text" class="form-control bold" id="notesInPopUp" autocomplete="off"
                           [(ngModel)]="notesInPopUp" formControlName="notesInPopUp" disabled/>   
                        </div>
                      </div>
                    </form>
                  </div>
              </div>
          </div>
        </div>
      </div>
      </ng-template>