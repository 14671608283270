export class MondayAllocation {
    constructor(
        public AdvisorName?: string,
        public CompanyName?: string,
        public Login?: string,
        public Email?: string,
        public AccountName?: string,
        public AccountNumber?: string,
        public AccountClassification?: string,
        public AccountType?: string,
        public Custodian?: string,
        public InstructionType?: string,
        public HighYieldCorporateBond?: string,
        public StrategicEnhancedBond?: string,
        public LogicInvestSleepWellBond?: string,
        public CashIsKingStrategy?: string,
        public StrategicHedgedIncome?: string,
        public EconomicCycle?: string,
        public Foundation?: string,
        public LogicInvestGlobalSector?: string,
        public LogicInvestCountryRotation?: string,
        // public ShareholderTotalReturn?: string,
        public PrimeDividendInternational?: string,
        public StrategicMidCap?: string,
        public USPrimeDividend?: string,
        public USPrimeDividendJr?: string,
        public NASDAQLeaders?: string,
        public SectorRotation?: string,
        public ValueDiscount?: string,
        public ValueExposure?: string,
        public AdaptiveRotation?: string,
        public AccountSize?: string,
        public PendingAnnuities?: string,
        public HeldPositions?: string,
        public Notes?: string,
    ) {}
}