import { Component, OnInit } from '@angular/core';
import {formatDate} from '@angular/common';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators, CheckboxControlValueAccessor } from "@angular/forms";
import { Router } from '@angular/router';
import { ClientInfoDataService } from './client-info-data.service';
import { DynamicSidebarService } from '../../sidebar/dynamic-sidebar.service';
import { LocalStorage } from '../../util/localstorage.service';
import { SharedServiceService } from '../../util/shared-service.service';
import { Client } from '../../models/client.model';
import { Agent } from '../../models/agent.model';  
import { Choice } from '../../models/choice.model';
import { AlertService } from '../../util/alert/alert.service';
import { Constants } from '../../util/constants' 
//import { CollapseModule } from 'ngx-bootstrap/';

@Component({
  selector: 'app-client-info',
  templateUrl: './client-info.component.html',
  styleUrls: ['./client-info.component.scss']
})
export class ClientInfoComponent implements OnInit {

//#region "Variable declaration"
  public selectClientID: string = "";
  public loggedAgent: Agent 
  public isShowDashboard: boolean = false
  public client: Client = new Client
  clientInfoForm: FormGroup;
  agentInfoForm: FormGroup;
  clientDOB : any
  clientAge : any 
  clientRetirementDate : any
  clientRetirementAge : any
  spouseDOB : any 
  spouseAge : any
  spouseRetirementDate : any
  spouseRetirementAge: any
  public hasSpouse : boolean
  createdBy : any
  orderID :number = 0  
  public organizations: Array<any>;
  public agents: Array<any>;
  public selectedorganization: string;
  public selectedagent: string;
  public isMarketer: boolean = false
  public maxdate = new Date();
//#endregion

//#region "constructor,  and ngOnInit"
  constructor(private router: Router, private ldService: ClientInfoDataService,
                private formBuilder: FormBuilder, private alertService: AlertService,
                //private dynSidebarService: DynamicSidebarService,
                private sharedService: SharedServiceService) { }

  ngOnInit() {
    this.alertService.clear()
    this.loggedAgent   = JSON.parse(localStorage.getItem(LocalStorage.LOGGED_AGENT)) as Agent; 
		if(this.loggedAgent.UserRoleID== Constants.UserRoleID.Superuser)
      this.isShowDashboard = true
      
    this.clientInfoForm = this.formBuilder.group({
			clientFirstName: new FormControl(null),
      clientLastName: new FormControl(null),
      clientDOB:new FormControl(null),
      clientAge: new FormControl(null),
      clientRetirementAge: new FormControl(null),
      clientRetirementDate : new FormControl(null),
      spouseFirstName: new FormControl(null),
      spouseLastName: new FormControl(null),
      spouseDOB : new FormControl(null), 
      spouseAge : new FormControl(null),
      spouseRetirementAge: new FormControl(null), 
      spouseRetirementDate : new FormControl(null) ,
      comment : new FormControl(null) ,
      financialConcerns : new FormControl(null) 
       
    });
    

    this.agentInfoForm = this.formBuilder.group({
			selectedorganization: new FormControl(null),
      selectedagent: new FormControl(null)
		});
    
    this.selectClientID = localStorage.getItem(LocalStorage.SELECTED_CLIENTID)

   //alert(this.selectClientID)
    if (this.selectClientID =="") // new client 
    { 
      
        this.client = new Client 
        this.client.Choice = [] 
        this.orderID=0 

        this.clientDOB  = null
        this.clientAge = 0
        this.clientRetirementDate =  null
        this.clientRetirementAge = 0  
        this.spouseDOB  = null
        this.spouseRetirementDate= null
        this.spouseAge = 0
        this.spouseRetirementAge = 0 
        this.selectedorganization = this.loggedAgent.OrganizationID
        this.selectedagent = this.loggedAgent.AgentID
      
        //this.sharedService.changeSideBarRequest() 
       //localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(new Client));
    } 
    else // existing client
    {  

      this.client   = JSON.parse(localStorage.getItem(LocalStorage.CURRENT_CLIENT)) as Client; 
      this.assignClientInformation()      
       

     /*  this.ldService.getclientbyid(this.selectClientID, this.loggedAgent).subscribe(data => 
        {
          this.client= data   
          localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));    
          this.assignClientInformation()     
          this.sharedService.changeSideBarRequest() 
        
        },
        error => {
          this.alertService.clear()
          this.alertService.error('Error occured while retrieving data')
         });   */
    }   

    this.sharedService.changeSideBarRequest()   
    if(this.loggedAgent.bMarketer){
      this.getOrganizations()
      this.getAgentsByOrganizationID(this.selectedorganization)
      this.isMarketer = true
    }
  }
//#endregion

//#region "get organization and agent list"
    getOrganizations()
    {
      this.alertService.clear()
      this.ldService.getorganizations(this.loggedAgent).subscribe(data => {
        this.organizations  = data
      },
      error => { 
        this.alertService.error('An error occurred')
      });	 
    } 
    
    onSelectOrganization(){
      this.getAgentsByOrganizationID(this.selectedorganization)
      if(this.agents != undefined && this.agents.length != 0 && this.agents.length != 0){
        this.selectedagent = this.agents[0].AgentID
      }else{
        this.selectedagent = null
      }
    }

    getAgentsByOrganizationID(selectedorganization)
    {
      this.alertService.clear()
      this.agents = []
      this.ldService.getagents(selectedorganization,this.loggedAgent).subscribe(data => {
        this.agents = data
      },
      error => { 
        this.alertService.error('An error occurred')
      });	 
    }

  //#endregion

//#region "on input field changes"
  onClientDOBChange(event: any) { 
    if  (typeof this.clientInfoForm.controls.clientFirstName.value === 'undefined' || 
          typeof this.clientInfoForm.controls.clientLastName.value === 'undefined') 
    {
        return
    }
    this.clientDOB = new Date(event);
    var y : Date = new Date( this.clientDOB) 
    this.clientAge= new Date().getFullYear() - y.getFullYear();
    this.onClientRetirementAgeTextChange(this.clientRetirementAge)
  }

  onSpouseDOBChange(event: any) { 
    if  (typeof this.clientInfoForm.controls.spouseFirstName.value === 'undefined' || 
          typeof this.clientInfoForm.controls.spouseLastName.value === 'undefined') 
    {
        return
    }

    if  (this.clientInfoForm.controls.spouseFirstName.value != "" && 
        this.clientInfoForm.controls.spouseLastName.value != "") 
      {
            this.spouseDOB = new Date(event);
            var y : Date = new Date( this.spouseDOB) 
            this.spouseAge= new Date().getFullYear() - y.getFullYear(); 
            this.onSpouseRetirementAgeTextChange(this.spouseRetirementAge)
      }
  }

  onClientRetirementDateChange(event: any) { 
    if  (typeof this.clientInfoForm.controls.clientFirstName.value === 'undefined' || 
          typeof this.clientInfoForm.controls.clientLastName.value === 'undefined') 
    {
        return
    }

    if (!event) {
      console.warn('No date input provided');
      this.clientRetirementDate = null;
      return;
  }


    const date = new Date(event);

    if (date.toString() === 'Invalid Date' || this.clientRetirementAge <= 0) {
        this.clientRetirementDate = null;
        this.client.ClientRetirementAge = 0;
        this.clientRetirementAge = 0;
        console.warn('Invalid date entered:', event);
        console.warn('Invalid age entered:',  this.clientRetirementAge);
        return;
    }

      this.clientRetirementDate =  new Date(event);
      var y : Date = new Date( event) 
      this.client.ClientRetirementAge=   y.getFullYear() - new Date(this.clientDOB).getFullYear(); 
      this.clientRetirementAge=this.client.ClientRetirementAge
  }  

  onSpouseRetirementDateChange(event: any) { 
    if  (typeof this.clientInfoForm.controls.spouseFirstName.value === 'undefined' || 
          typeof this.clientInfoForm.controls.spouseLastName.value === 'undefined') 
    {
        return
    }

    if (!event) {
      console.warn('No date input provided');
      this.spouseRetirementDate = null;  
      return;
  }

  if (this.clientInfoForm.controls.spouseFirstName.value !== "" && 
      this.clientInfoForm.controls.spouseLastName.value !== "") {

      const date = new Date(event);

      if (date.toString() === 'Invalid Date' || this.spouseRetirementAge <= 0) {
          this.spouseRetirementDate = null;
          this.client.SpouseRetirementAge = 0;
          this.spouseRetirementAge = 0;
          console.warn('Invalid date entered:', event);
          console.warn('Invalid age entered:', this.spouseRetirementAge);
          return;
      }

      this.spouseRetirementDate = date;
      const y = new Date(event);
      this.client.SpouseRetirementAge = y.getFullYear() - new Date(this.spouseDOB).getFullYear();
      this.spouseRetirementAge = this.client.SpouseRetirementAge;
  }
  }

onClientRetirementAgeTextChange(event) {

  let valueWithoutCommas = event.target.value.replace(/\D/g, "");

  if (Number(valueWithoutCommas) > 0) {
      this.clientInfoForm.controls.clientRetirementAge.setValue(valueWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  } else {
      this.clientInfoForm.controls.clientRetirementAge.setValue('');  
     
  }

  if (typeof this.clientInfoForm.controls.clientFirstName.value === 'undefined' || 
      typeof this.clientInfoForm.controls.clientLastName.value === 'undefined' || valueWithoutCommas === "") {
      return;
  }

  if (valueWithoutCommas !== '') { 
      const ageNumber = Number(valueWithoutCommas);
      const userdob = new Date(this.clientDOB);  
      this.client.ClientRetirementDate = new Date(userdob.setFullYear(userdob.getFullYear() + ageNumber));
      this.clientRetirementDate = this.client.ClientRetirementDate;
  }
}

onSpouseRetirementAgeTextChange(event: any) {
  let valueWithoutCommas = event.target.value.replace(/\D/g, "");

  if (Number(valueWithoutCommas) > 0) {
      this.clientInfoForm.controls.spouseRetirementAge.setValue(valueWithoutCommas.replace(/\B(?=(\d{3})+(?!\d))/g, ","));
  } else {
      this.clientInfoForm.controls.spouseRetirementAge.setValue('');  
  }
  if (typeof this.clientInfoForm.controls.spouseFirstName.value === 'undefined' || 
      typeof this.clientInfoForm.controls.spouseLastName.value === 'undefined' || 
      this.clientInfoForm.controls.spouseFirstName.value === "" || 
      this.clientInfoForm.controls.spouseLastName.value === "") {
      return;
  }

  if (valueWithoutCommas !== '') { 
      const ageNumber = Number(valueWithoutCommas);
      const userdob = new Date(this.spouseDOB);  
      this.client.SpouseRetirementDate = new Date(userdob.setFullYear(userdob.getFullYear() + ageNumber));
      this.spouseRetirementDate = this.client.SpouseRetirementDate;
  }
}


//#endregion

//#region "button click events"
  saveBtnClickEvent()
  {
    // Validation
    if (this.validateClientInfo()){
      //Assign Data 
      this.setClientInformation()

      //Save Client to database
      this.saveClient()

      localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(this.client));  
      localStorage.setItem(LocalStorage.SELECTED_CLIENTID, JSON.stringify(this.client.ClientID));  
      this.selectClientID = this.client.ClientID;
			this.sharedService.changeSideBarRequest()   
    }
  }

  cancelBtnClickEvent()
  {
     
  }

  saveClient()
  {
    this.ldService.saveclient(this.client, this.loggedAgent).subscribe(date =>
      {
        this.alertService.clear()
        this.alertService.success('Successfully saved')
      },
      error => {
        this.alertService.clear()
        this.alertService.error('Error occured while saving data')
       });
  }

  exitBtnClickEvent()
  {
    localStorage.setItem(LocalStorage.SELECTED_CLIENTID, "");
    //localStorage.setItem(LocalStorage.CURRENT_CLIENT, JSON.stringify(new Client));
    localStorage.removeItem(LocalStorage.CURRENT_CLIENT);
    this.router.navigateByUrl('landing'); 
  }

  //load the superuser dashboard page
	public navigateSuperUserDashboard()
	{
		this.router.navigateByUrl('superuserdashboard'); 
	}
//#endregion

//#region "set client info and set choice info"
  setClientInformation()
  {  
    this.client.ClientFirstName = this.clientInfoForm.controls.clientFirstName.value;  
    this.client.ClientLastName =this.clientInfoForm.controls.clientLastName.value;  
    this.client.ClientDOB =this.clientDOB;  
    this.client.ClientRetirementAge =this.clientInfoForm.controls.clientRetirementAge.value;  
    this.client.Comment = this.clientInfoForm.controls.comment.value;  
    this.client.FinancialConcerns =this.clientInfoForm.controls.financialConcerns.value;  
    this.client.ClientRetirementDate =this.clientRetirementDate;  
    if(this.createdBy == null || this.createdBy == undefined || this.createdBy == ""){
      this.createdBy = this.loggedAgent.AgentID
    }
    if(this.loggedAgent.bMarketer){
      this.client.AgentID = this.selectedagent
      this.client.CreatedBy = this.loggedAgent.AgentID
    }else{
      this.client.AgentID = this.loggedAgent.AgentID
      this.client.CreatedBy = this.createdBy
    }

    if (this.selectClientID !="")
    {
        this.client.ClientID = this.selectClientID.replace(/\\/g, '').replace(/['"]+/g, '');       
    }
    else{
        this.client.ClientID = this.guid()
        this.selectClientID = this.client.ClientID
        this.client.GeneratedOn = new Date()
        this.setChoiceInfomation(true,null,null) 
    } 

    if  (this.clientInfoForm.controls.spouseFirstName.value != "" && 
            this.clientInfoForm.controls.spouseLastName.value != "") 
    {
        this.client.SpouseFirstName =this.clientInfoForm.controls.spouseFirstName.value;  
        this.client.SpouseLastName = this.clientInfoForm.controls.spouseLastName.value;  
        this.client.SpouseDOB = this.spouseDOB;  
        this.client.SpouseRetirementAge = this.clientInfoForm.controls.spouseRetirementAge.value;  
        this.client.SpouseRetirementDate = this.spouseRetirementDate;  
        this.hasSpouse = true
    }    
    else{ 
        this.hasSpouse = false
    } 
  }

  setChoiceInfomation(bCurrent:boolean,CopyFromChoiceID :any, ChoiceName : any)
  {

    var oChoice : Choice = new Choice 
    var ChoiceID : string = this.guid()

    var CurrentYear : number = new Date().getFullYear()
    var ClientAge : number = new Date().getFullYear() - new Date(this.clientDOB).getFullYear();  
    var SpouseAge : number=  new Date().getFullYear() - new Date(this.spouseDOB).getFullYear();  
  
    var LastYear : Number= CurrentYear + ( Constants.LAST_YEAR - ClientAge)
   // alert()

    if (bCurrent==true) // Current based Choice
    { 

      oChoice.ChoiceID = ChoiceID
      oChoice.ChoiceName = Constants.CURRENT_CHOICE_NAME
      oChoice.IsActiveChoice = true
      oChoice.IsReadOnly = false
      oChoice.ReportKind = Constants.REPORT_TYPE.Rpt_Default
      oChoice.ClientDiesAtAge = 0
      oChoice.SpouseDiesAtAge = 0
      oChoice.ReportTitleLine1 = Constants.REPORT_NAME
      oChoice.ReportTitleLine2 = this.client.ClientFirstName + " " + this.client.ClientLastName
      oChoice.ReportTitleLine3 =formatDate(new Date(this.client.GeneratedOn), 'MM/dd/yyyy', 'en')  
      oChoice.ModelStartYear = CurrentYear.toString()
      oChoice.ModelEndYear = LastYear.toString()
      oChoice.ViewStartYear = CurrentYear.toString()
      oChoice.ViewEndYear = LastYear.toString()
      oChoice.NumYearsInView = ""
      oChoice.DisplayOrder = this.getNextOrder(Constants.TABLE_NAME.Choice)

      this.client.Choice.push(oChoice)

      //alert(JSON.stringify( this.client.Choice))
     // mActiveChoiceID = ChoiceID
    
    }   

  }
//#endregion

//#region "validations and calculations"
  validateClientInfo():boolean
  {
// alert(this.clientInfoForm.controls.clientFirstName.value)
    //Validate Client Info
    if ((this.selectedorganization == null || this.selectedorganization == undefined || this.selectedorganization == "" ) && this.isMarketer){ 
      this.alertService.clear()
			this.alertService.error('Invalid Organization')
      return false
    }

    if ((this.selectedagent == null || this.selectedagent == undefined || this.selectedagent == "" ) && this.isMarketer){ 
      this.alertService.clear()
			this.alertService.error('Invalid Agent')
      return false
    }

    if ((this.clientInfoForm.controls.clientFirstName.value == ""  || 
          this.clientInfoForm.controls.clientFirstName.value == null  || 
          this.clientInfoForm.controls.clientLastName.value == "" || 
          this.clientInfoForm.controls.clientLastName.value == null ))
    { 
      this.alertService.clear()
			this.alertService.error('Invalid Client Name')
      return false
    } 

    if (this.clientDOB == null || this.clientDOB.toString()=='Invalid Date' || this.clientAge < 0){ 
      this.alertService.clear()
			this.alertService.error('Invalid Client Date of Birth')
      return false
    }

    if (this.clientRetirementDate == null || this.clientRetirementDate.toString()=='Invalid Date' ){
      this.alertService.clear()
			this.alertService.error('Invalid Client Retirement Date')
      return false
    } 

    if (this.clientRetirementAge == null || this.clientRetirementAge == 0 ){
      this.alertService.clear()
			this.alertService.error('Invalid Client Retirement Age')
      return false
    } 

    //Validate Spouse Info
    if ((this.clientInfoForm.controls.spouseFirstName.value === "" || this.clientInfoForm.controls.spouseFirstName.value == null)  &&
          (   this.clientInfoForm.controls.spouseLastName.value === "" ||  this.clientInfoForm.controls.spouseLastName.value == null))
            {
                // no spouse.. no need to do anything
            }   
    else {
    
      if (this.clientInfoForm.controls.spouseFirstName.value == null ||  
            this.clientInfoForm.controls.spouseLastName.value == null ||
            this.clientInfoForm.controls.spouseFirstName.value === "" ||  
            this.clientInfoForm.controls.spouseLastName.value === "")
      {     
          this.alertService.clear()
          this.alertService.error('Invalid Spouse Name')
          return false
      } 

      if (this.spouseDOB == null || this.spouseDOB.toString()=='Invalid Date' || this.spouseAge < 0){
        this.alertService.clear()
        this.alertService.error('Invalid Spouse Date of Birth')
        return false
      }
  
      if (this.spouseRetirementDate == null || this.spouseRetirementDate.toString()=='Invalid Date' ){
        this.alertService.clear()
        this.alertService.error('Invalid Spouse Retirement Date')
        return false
      }

      if (this.spouseRetirementAge == null || this.spouseRetirementAge == 0 ){
        this.alertService.clear()
        this.alertService.error('Invalid Spouse Retirement Age')
        return false
      }
  
    }
    return true
  }

  calculateAge = function (DOB:Date) {
    var y : Date = new Date(DOB) 
    return new Date().getFullYear() - y.getFullYear();
  }
//#endregion

  getNextOrder(incometype :string) : any{ 
     var currentOrderID :number = 0
     if (incometype = Constants.TABLE_NAME.IncomeSources)  
     {
       /*  var tempIncomeSourceAccount As New List(Of Models.IncomeSourceAccount)
         tempIncomeSourceAccount = oClient.IncomeSourceAccount.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList

         If tempIncomeSourceAccount.Count > 0 Then
             currentOrderID = tempIncomeSourceAccount.Max(Function(g) g.DisplayOrder)
         Else
             currentOrderID = 0
         End If
         */
     }
     else  if (incometype = Constants.TABLE_NAME.IncomeFromAssets)  
     {
        /* var tempIncomeFromAsset As New List(Of Models.IncomeFromAsset)
         tempIncomeFromAsset = oClient.IncomeFromAsset.Where(Function(x) x.ChoiceID.ToString = mSelectedChoiceID.ToString()).ToList
         If tempIncomeFromAsset.Count > 0 Then
             currentOrderID = tempIncomeFromAsset.Max(Function(g) g.DisplayOrder) + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
         Else
             currentOrderID = 0 + 20 ' Adding 20 to make sure new IncomeFromAssest goes to last
         End If*/
     }
     else if (incometype = Constants.TABLE_NAME.Choice)     
     {      
         if (this.client.Choice.length > 0 )
             currentOrderID = Math.max.apply(Math, this.client.Choice.map(function(o) { return o.DisplayOrder; }))
         else
             currentOrderID = 0          
     }


     this.orderID = currentOrderID + 1 //iOrder + 1
     return this.orderID

  }

  assignClientInformation()
  {
    this.selectedorganization = this.client.OrganizationID
    this.selectedagent = this.client.AgentID
    this.clientDOB  =  new Date(this.client.ClientDOB)
    this.clientAge = this.calculateAge(this.client.ClientDOB)  
    this.clientRetirementDate =  new Date(this.client.ClientRetirementDate)
    this.clientRetirementAge = this.client.ClientRetirementAge//new Date(this.clientRetirementDate).getFullYear() - new Date(this.clientDOB).getFullYear();
    if  (this.client.SpouseFirstName != "" && this.client.SpouseLastName != "" && 
    this.client.SpouseFirstName != undefined && this.client.SpouseLastName != undefined) 
    { 
      this.spouseDOB  =  new Date(this.client.SpouseDOB)
      this.spouseAge = this.calculateAge(this.client.SpouseDOB)  
      this.spouseRetirementDate =  new Date(this.client.SpouseRetirementDate)
      this.spouseRetirementAge= this.client.SpouseRetirementAge
      this.hasSpouse=true
    }
    else
    { 
      this.spouseDOB  = null
      this.spouseRetirementDate= null
      this.spouseAge = 0
      this.spouseRetirementAge = 0
      this.hasSpouse=false 
    }
    this.createdBy = this.client.CreatedBy
  }

  private s4(): string {
		return Math.floor((1 + Math.random()) * 0x10000)
			.toString(16)
			.substring(1);
  }
  
  private guid(): string {
		return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' + this.s4() + this.s4() + this.s4();
  }
  





}
