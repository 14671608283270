<div class="main-panel pt-0 pb-4">
	<div class="container-fluid bold">
		<div class="col-sm-12 px-0">
			<h3 class="light-header bold">
				{{ 'CLIINFO.LBL_TITLE' | translate}}
				<button type="button" class="btn btn-primary btn-sm float-right" (click)="exitBtnClickEvent()">
					<i class="fas fa-sign-out"></i> Exit
				</button>
				<button type="button" class="btn btn-primary btn-sm mr-1 float-right" *ngIf="isShowDashboard" (click)="navigateSuperUserDashboard()">
					{{ 'SUPERUSER_DASBOARD.LBL_TITLE' | translate }}
				</button>
			</h3>
			<hr class="mt-0 mb-1 pb-3" />
			<div class="container border rounded p-3 mb-3" *ngIf="isMarketer">
				<h5 class="light-header bold">
					{{ 'CLIINFO.LBL_FORM_TITLE_1' | translate}}
				</h5> 
				<hr class="mt-0 mb-3" />
				<form [formGroup]="agentInfoForm">
					<div class="form-row">
						<div class="form-group col-md-6">
							<label class="mr-3" for="Fname">Organization</label>
							<select class="form-control bold" placeholder="Organization" formControlName="selectedorganization"  [(ngModel)]="selectedorganization" (change)="onSelectOrganization()"> 
								<option *ngFor="let item of organizations;" [value]="item.OrganizationID">
									{{item.OrganizationName}}
								</option>
							</select> 
						</div>
						<div class="form-group col-md-6">
							<label class="mr-3" for="Fname">Agent</label>
							<select class="form-control bold" placeholder="Agent" formControlName="selectedagent"  [(ngModel)]="selectedagent"> 
								<option *ngFor="let item of agents;" [value]="item.AgentID">
									{{item.LastName}} {{item.FirstName}}
								</option>
							</select> 
						</div>
					</div>
				</form>
			</div>
			<div class="container border rounded p-3">
				<!--Client information Start-->
				<h5 class="light-header bold">
					{{ 'CLIINFO.LBL_FORM_TITLE_2' | translate}}
				</h5>  
				 <hr class="mt-0 mb-3" />
				<form [formGroup]="clientInfoForm">
					<div class="form-row">
						<div class="form-group col-md-6">
							<label for="Fname">Client First Name</label>
							<input type="text" class="form-control bold" placeholder="Client First Name" name="clientFirstName" 
							formControlName="clientFirstName"  id="clientFirstName"   
							[(ngModel)]="client.ClientFirstName"> 
						</div>
						<div class="col">
							<label for="Lname">Client Last Name</label>
							<input type="text" class="form-control bold" placeholder="Client Last Name" name="clientLastName" 
							formControlName="clientLastName"  id="clientLastName"   [(ngModel)]="client.ClientLastName"  >
						</div>
					</div>
					
					<div class="form-row">
						<div class="col-sm-4">
								<label for="dob"> Date Of Birth</label>
								<input type="text" class="form-control bold" bsDatepicker (bsValueChange)="onClientDOBChange($event)"
								[bsValue]= "clientDOB" [maxDate]="maxdate" value="{{ clientDOB | date:'MM/dd/yyyy' }}"
								[bsConfig]="{ containerClass: 'theme-default' }"
								id="clientDOB">
						</div>
						<div class="col-sm-1" style="margin-right: 90px;">
								<label for="empty">&nbsp;</label>
								<input type="text" class="form-control bold" placeholder="0" readonly name="clientAge" 
								formControlName="clientAge"  id="clientAge"    
								[(ngModel)]="clientAge"> 	
						</div>
						
						<div class="col-sm-2">
								<label for="clientRetirementAge"  > Retirement Age</label>
								<input type="text" min="0" step="1" class="form-control bold" placeholder="0" name="clientRetirementAge" 
								formControlName="clientRetirementAge"  id="clientRetirementAge"   (keyup)= "onClientRetirementAgeTextChange($event)"
								[(ngModel)]="clientRetirementAge"> 
						</div>

						<div class="col">
							<div class="col-sm-4">
									<label for="empty">&nbsp;</label>
									<input type="text" class="form-control bold" style="width: 250px; margin-left: -10px;" bsDatepicker (bsValueChange)="onClientRetirementDateChange($event)"
									[bsValue]= "clientRetirementDate"    value="{{ clientRetirementDate | date:'MM/dd/yyyy' }}"
									[bsConfig]="{  containerClass: 'theme-default' }"
									id="clientRetirementDate"> 
							</div>

							<!--<div class="input-group mb-3">
								<input type="date" class="form-control" aria-describedby="basic-addon1">
								<div class="input-group-prepend">
									<span class="input-group-text" id="basic-addon1"><i
											class="far fa-calendar-alt"></i></span>
								</div>
							</div>-->
						</div>
					</div>
					<!--Client information End-->
					
					<br>
					<!--Spouse information Start-->
					<h5 class="light-header bold">
						{{ 'CLIINFO.LBL_FORM_TITLE' | translate}}
					</h5 >
					<hr class="mt-0 mb-3" />
					<div class="form-row">
						<div class="form-group col-md-6">
							<label for="Fname">Spouse First Name</label> 
							<input type="text" class="form-control bold" placeholder="Spouse First Name" name="spouseFirstName" 
							formControlName="spouseFirstName"  id="spouseFirstName"   
							[(ngModel)]="client.SpouseFirstName"> 
						</div>
						<div class="col">
							<label for="Lname">Spouse Last Name</label> 
							<input type="text" class="form-control bold" placeholder="Spouse Last Name" name="spouseLastName" 
							formControlName="spouseLastName"  id="spouseLastName"   
							[(ngModel)]="client.SpouseLastName"> 
						</div>
					</div>
					<!--div class="form-row">
						<label for="dob" class="form-group col-md-6"> Date Of Birth</label>
					</div-->
					<div class="form-row">
						<!--<div class="form-group col-sm-2">
							<select class="form-control" id="month">
								<option>Month</option>
								<option value='1'>Janaury</option>
								<option value='2'>February</option>
								<option value='3'>March</option>
								<option value='4'>April</option>
								<option value='5'>May</option>
								<option value='6'>June</option>
								<option value='7'>July</option>
								<option value='8'>August</option>
								<option value='9'>September</option>
								<option value='10'>October</option>
								<option value='11'>November</option>
								<option value='12'>December</option>
							</select>
						</div>
						<div class="col-sm-2">
							<select class="form-control" id="day">
								<option>Day</option>
								<option value="1">1</option>
								<option value="2">2</option>
								<option value="3">3</option>
								<option value="4">4</option>
								<option value="5">5</option>
								<option value="6">6</option>
								<option value="7">7</option>
								<option value="8">8</option>
								<option value="9">9</option>
								<option value="10">10</option>
								<option value="11">11</option>
								<option value="12">12</option>
								<option value="13">13</option>
								<option value="14">14</option>
								<option value="15">15</option>
								<option value="16">16</option>
								<option value="17">17</option>
								<option value="18">18</option>
								<option value="19">19</option>
								<option value="20">20</option>
								<option value="21">21</option>
								<option value="22">22</option>
								<option value="23">23</option>
								<option value="24">24</option>
								<option value="25">25</option>
								<option value="26">26</option>
								<option value="27">27</option>
								<option value="28">28</option>
								<option value="29">29</option>
								<option value="30">30</option>
								<option value="31">31</option>
							</select>
						</div>
						<div class="col-sm-2">
							<input type="text" class="form-control" placeholder="Year">
						</div> 
						-->

						<div class="col-sm-4">
								<!--label for="dob" class="form-group"> Date Of Birth</label-->
								<label for="dob"> Date Of Birth</label>
							<input type="text" class="form-control bold" bsDatepicker
								(bsValueChange)="onSpouseDOBChange($event)" [bsValue]="spouseDOB"
								value="{{ spouseDOB | date:'MM/dd/yyyy' }}" [maxDate]="maxdate"
								[bsConfig]="{ containerClass: 'theme-default' }" id="spouseDOB">
						</div>
						<div class="col-sm-1" style="margin-right: 90px;">
								<label for="empty">&nbsp;</label>
							<input type="text" style="width: 100%" class="form-control bold" placeholder="0" readonly name="spouseAge"
								formControlName="spouseAge" id="spouseAge" [(ngModel)]="spouseAge">
						</div>
						
						<div class="col-sm-2">
								<label for="RetirementAge"  > Retirement Age</label>
							<input type="text" min="0" step="1" class="form-control bold" placeholder="0" name="spouseRetirementAge"
								formControlName="spouseRetirementAge" id="spouseRetirementAge"
								(keyup)="onSpouseRetirementAgeTextChange($event)"
								[(ngModel)]="spouseRetirementAge">
						</div>

						<div class="col">
							<div class="col-sm-4">
									<label for="empty">&nbsp;</label>
								<input type="text" style="width: 250px; margin-left: -10px;" class="form-control bold" bsDatepicker (bsValueChange)="onSpouseRetirementDateChange($event)"
									[bsValue]="spouseRetirementDate"
									value="{{ spouseRetirementDate | date:'MM/dd/yyyy' }}"
									[bsConfig]="{ containerClass: 'theme-default' }" id="spouseRetirementDate">
							</div>

							

							<!--<div class="input-group mb-3">
								<input type="date" class="form-control" aria-describedby="basic-addon1">
								<div class="input-group-prepend">
									<span class="input-group-text" id="basic-addon1"><i
											class="far fa-calendar-alt"></i></span>
								</div>
							</div>-->
						</div>
					</div>
					<!--Spouse information End-->

					<!--Common details Start-->
					<br>
					<hr class="mt-0 mb-3" />
					<div class="form-row">
						<div class="form-group col-md-6">
							<label for="Comments">Comments</label>
							<textarea class="form-control bold"  rows="3"
							name="comment" 
							formControlName="comment"  id="comment"
							[(ngModel)]="client.Comment"></textarea> 
						</div>
						<div class="form-group col-md-6">
							<label for="pfc">Primary Financial Concerns</label>
							<textarea class="form-control bold"  rows="3" 
							name="financialConcerns" 
							formControlName="financialConcerns"  id="financialConcerns"
							[(ngModel)]="client.FinancialConcerns"></textarea> 
						</div>
					</div>
					<div class="form-row">
						<div class="form-group col-md-0">
							<button type="button" class="btn btn-primary btn-sm" (click)="saveBtnClickEvent()"> <i class="far fa-save"></i> Save</button>
						</div>
						<div class="form-group col-md-0">
							<button type="button" class="btn btn-primary btn-sm" (click)="cancelBtnClickEvent()"> <i class="far fa-times"></i> Cancel</button>
						</div>
						<!--<div class="form-group col-md-0">
							<button type="button" class="btn btn-primary">Close</button>
						</div>-->
					</div>
				</form>
			</div>
		</div>
	</div>
</div>