export class Constants {
    public static COMP_NAME = {
        'cli_inf': 'Client Information',
        'inc_sou': 'Income Sources',
        'inc_fro_ass': 'Income from Asserts',
        'inc_lif_rep': 'Income for life Report',
        'ris_gra': 'Risk Graph',
        'inv_rep': 'Investment Report',
        'old_inv_rep': 'Old Investment Report',
        'wealth_rep': 'Wealth Report',
        'dashboard': 'Dashboard',
        'company_config': 'Company Configuration',
        'expense': 'Expense',
        'revenue': 'Revenue',
        'marketing_production': 'MarketingProduction'
    }    

    public static ALERT_TYPE = {
        'success': 'alert alert-success',
        'danger': 'alert alert-danger',
        'info': 'alert alert-info',
        'warning': 'alert alert-warning'
    }

    public static MODULE_TYPE = {
        HOME: 0,
        FP4FA: 1
    }

    public static LANDING_HEADER_OPTION = {
        isHamburger: false,
        isSearch: false,
        isNotification: false,
        isOtherMenu: false,
        isTopRightMenu:true
    }

    public static HOME_HEADER_OPTION = {
        isHamburger: true,
        isSearch: false,
        isNotification: false,
        isOtherMenu: false,
        isTopRightMenu:true
    }

    public static BISIQ_HEADER_OPTION = {
        isHamburger: true,
        isSearch: false,
        isNotification: false,
        isOtherMenu: false,
        isTopRightMenu:false
    }
    
    public static AUTHORIZATION = 'basic ZGluaXRoaWpAYXRsaW5rY29tLmNvbToxMjM='
    public static LAST_YEAR = 100
    public static DEFAULT_CHOICE_NAME = "New"//"Default"
    public static CURRENT_CHOICE_NAME = "(Work)"//"(Current)"
    public static REPORT_NAME = "Longevity Report" 
    public static FTAOrganizationID = "e2671f9d-6c9f-11e9-856b-dc4a3ef770fd"


    public static REPORT_TYPE = {
        Rpt_Default: 'Rpt_Default',
        Rpt_ClientDies: 'Rpt_ClientDies',
        Rpt_SpouseDies: 'Rpt_SpouseDies' 
    } 

    public static TABLE_NAME = {
        Client: 'Client',
        Choice: 'Choice',
        IncomeSources: 'IncomeSources',
        IncomeFromAssets: 'IncomeFromAssets'
    } 


    public static  IncomeType = {
        IncomeSources: "IncomeSources",
        IncomeFromAssets: "IncomeFromAssets"
    }

    public static  UserRoleID = {
        Superuser: "0",
        Admin: "1",
        InvestmentsAndInsurance: "2",
        InvestmentOnly: "3",
        InsuranceOnly: "4",
    }
	public static  Blend = {
        Blends: 0,
        MyBlends: 1
    }
	public static  IndexBlend = {
        IndexBlends: 0,
        MyIndexBlends: 1
    }
    public static  TickerBlend = {
        TickerBlends: 0,
        MyTickerBlends: 1
    }
	public static  SavedReportType = {
        OldZephyr: "0",
        RiskGraph: "1",
		InvestmentReport: "2"
    }
 
    public static FieldError = {
        InvalidFieldDataType: "Invalid Date"
    }

    public static ExpenseCategories = {
        Advertising: "Advertising",
        Employees: "Employees",
        AdvisorPay: "Advisor Pay",
        PayrollTaxes: "Payroll Taxes",
        COLAIncrease: "COLA Increase ( Starting in Year 2)",
        StaffPerformanceBonusPool: "Staff Performance Bonus Pool",
        InsuranceBenefits: "Insurance Benefits",
        RetirementBenefits: "Retirement Benefits",
        Rent: "Rent",
        EAndOInsurance: "Errors and Omissions Insurance ",
        BusinessLiabilityInsurance: "Business Liability Insurance ",
        WorkersCompensationInsurance: "Worker’s Compensation Insurance ",
        OtherInsuranceCosts: "Other Insurance Costs",
        OtherExpenses: "Other Expenses"
    }


    public static Months = {
        Jan: "Jan",
        Feb: "Feb",
        Mar: "Mar",
        Apr: "Apr",
        May: "May",
        Jun: "Jun",
        Jul: "Jul",
        Aug: "Aug",
        Sep: "Sep",
        Oct: "Oct",
        Nov: "Nov",
        Dec: "Dec"
    }
    

    public static MarketingChannels = {
        Workshops: "51d1908e-dd68-11eb-b9d8-00ff28044310",
        RadioShows: "51d19af8-dd68-11eb-b9d8-00ff28044310",
        RadioSpots: "51d19b8a-dd68-11eb-b9d8-00ff28044310",
        TV: "51d19bb0-dd68-11eb-b9d8-00ff28044310"
    }


    public static MondayAllocationColumns = {
        AdvisorName_ID: "name",
        CompanyName: "text5",
        Login: "text97",
        AccountName_ID: "text1",
        AccountNumber_ID: "text",
        AccountClassification_ID: "text7",
        AccountType_ID: "text_1",
        Custodian_ID: "status46",
        InstructionType_ID: "status1",
        Poeple_ID: "people",
        HighYieldCorporateBond_ID: "numbers",
        StrategicEnhancedBond_ID: "numbers3",
        LogicInvestSleepWellBond_ID: "dup__of_numbers0",
        CashIsKingStrategy_ID: "dup__of_sector_growth__1",
        StrategicHedgedIncome_ID: "dup__of_numbers",
        EconomicCycle_ID: "dup__of_dup__of_numbers",
        Foundation_ID: "dup__of_dup__of_dup__of_numbers22",
        LogicInvestGlobalSector_ID: "dup__of_dup__of_dup__of_numbers2",
        LogicInvestCountryRotation_ID: "dup__of_dup__of_dup__of_numbers1",
        PrimeDividendInternational_ID: "dup__of_dup__of_dup__of_numbers47",
        StrategicMidCap_ID: "dup__of_dup__of_dup__of_numbers4",
        USPrimeDividend_ID: "dup__of_dup__of_dup__of_numbers7",
        NASDAQLeaders_ID: "dup__of_dup__of_dup__of_numbers5",
        SectorRotation_ID: "dup__of_dup__of_dup__of_numbers8",
        ValueDiscount_ID: "dup__of_dup__of_dup__of_numbers",
        USPrimeDividendJr_ID: "u_s__prime_dividend_jr9",
        ValueExposure_ID: "value_exposure",
        AdaptiveRotationStrategy_ID:"numbers__1",
        // ShareholderTotalReturn_ID: "dup__of_sector_growth9__1",
        AccountSize_ID: "numbers5",
        PendingAnnuities_ID: "numbers7",
        HeldPositions_ID: "text4",
        Notes_ID: "text8",
        Email_ID: "text80",
        Files_ID: "files",
        SubmissionDate_ID: "creation_log"
    }

    public static MondayDepositWithdrawCashColumns = {
        AdvisorName_ID: "name",
        CompanyName: "text5",
        Login: "text97",
        AccountName_ID: "text1",
        AccountNumber_ID: "text",
        Amount_ID: "numbers5",
        Type_ID: "status14",
        PaymentFrequency_ID: "status0",
        PaymentStatus_ID: "status2",
        NextPaymentDate_ID: "date6",
        Notes_ID: "long_text",
        SubmissionDate_ID: "creation_log"
    }
}